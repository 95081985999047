<script>
  import {onMount} from "svelte"
  import {Modal} from "duo-kit"

  let modalopen

  onMount(()=>{
    console.log("cta modal loaded")
    setTimeout(()=>{showModal()},1000);
  })

  function showModal(){
    modalopen=true
  }

  function closeModal(){
    modalopen=null
  }
</script>

<div class="cta-modal">
  <Modal heading="Open Evening" open={modalopen} on:closed={closeModal} style="width:max(400px,50vw);max-width:1000px;">
    <h2>Our next Open Evening for pupils in Year 5, Year 6  and their Families is on Thursday 21st September 2023 (4.30pm – 7.30pm)</h2>
    <img src="/images/assets/shield.png/rs-200x200a.png" alt="" />
    <h3>Please email <a href="mailto:Admin@HalewoodAcademy.co.uk?subject=Open Evening Enquiry">Admin@HalewoodAcademy.co.uk</a> to register your attendance. We can’t wait to meet you!</h3>
  </Modal>
</div>

